import * as React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermsAndConditions from "../../components/TermsAndConditions"

const TermsAndConditionsPage = () => {
  return (
    <Layout noHeader noFooter>
      <SEO title="Terms and Conditions" />
      <TermsAndConditions />
    </Layout>
  )
}

export default TermsAndConditionsPage
